import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import classes from "./SimpleMarketing.module.scss";

const SimpleMarketing = (props) => {
  return (
    <div className={classes.FullScreenMessage} style={{ ...props.style }}>
      <div className={classes.Title} style={{ ...props.titleStyle }} key={20}>
        Welcome to Holy Minute
      </div>
      <div className={classes.Subtitle} key={21}>
        Holy Minute allows you to record your prayers and reflections and then
        post them for listening on the Holy Minute Alexa Skill and Google
        Action. Look for us soon on the Alexa Skill Store, App Store, and Google
        Play.
      </div>
      {/* {props.hideIcon ? null : (
        <div>
          <div className={classes.IconContainer}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "3em" }}
            ></FontAwesomeIcon>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default SimpleMarketing;
