import React from "react";
import SubmitButton from "../SubmitButton/SubmitButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import classes from "./LogoutButton.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as exampleActions from "../../store/actions/exampleStore";
import * as authActions from "../../store/actions/auth";

const LogoutButton = (props) => {
  const dispatch = useDispatch();

  const logoutAsync = async () => {
    try {
      const response = await dispatch(authActions.logout());
      //console.log("have signout response: ", response);
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    // <SubmitButton
    //   disabled={false}
    //   type="submit"
    //   onClick={logoutAsync}
    //   //onSubmissionFinish={onSubmissionFinish}
    //   hasSuccessfullySubmitted={false}
    //   isLoading={false}
    //   buttonStyle={{ backgroundColor: "#6d219e", color: "white" }}
    // >
    //   LOG OUT
    // </SubmitButton>
    <div
      className={classes.LogoutButton}
      style={props.hide ? { opacity: 0 } : {}}
      onClick={logoutAsync}
    >
      Log Out
    </div>
  );
};

export default LogoutButton;
