import React from "react";

import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";

//import classes from "./PrivacyPolicyPage.module.scss";

const TermsAndConditionsPage = (props) => {
  return (
    <div>
      {/* <h1>Home Page</h1> */}
      {/* <ProtectedContent></ProtectedContent> */}
      <TermsAndConditions></TermsAndConditions>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
    </div>
  );
};

export default TermsAndConditionsPage;
