import React, { useState, useEffect } from "react";
import { Spinner, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { writeLog } from "../../shared/logger";

import classes from "./AccountConfirmed.module.scss";

import SuccessMessage from "../../components/SuccessMessage/SuccessMessage";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

import { useDispatch } from "react-redux";
import * as authActions from "../../store/actions/auth";

const EXPIRED_CODE_EXCEPTION = "ExpiredCodeException";

export default function AccountConfirmed() {
  const dispatch = useDispatch();

  const history = useHistory();

  const { code, userName } = useParams();

  const [codeIsExpired, setCodeIsExpired] = useState(false);
  const [sentNewCode, setSentNewCode] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [formErrorMessage, setFormErrorMessage] = useState("");

  useEffect(() => {
    async function asyncAction() {
      if (isLoading) {
        try {
          writeLog(
            `have params in account confirmed - code: ${code}, userName: ${userName}`
          );
          await dispatch(authActions.confirmAccount(userName, code));
          //   const error = new Error("Something went wrong");
          //   error.code = EXPIRED_CODE_EXCEPTION;
          // throw error;
          //await new Promise((resolve) => setTimeout(resolve, 2000));
          setIsLoading(false);
        } catch (error) {
          //console.log("Caught error confirming account: ", error);

          if (error.code === EXPIRED_CODE_EXCEPTION) {
            setCodeIsExpired(true);
          }

          setIsLoading(false);
          setFormErrorMessage(error.message);
        }
      }
    }

    asyncAction();
  }, [isLoading]);

  const resendLinkClicked = async () => {
    writeLog("resend link clicked");
    try {
      await dispatch(authActions.resendConfirmation(userName));
      setSentNewCode(true);
      setFormErrorMessage("");
    } catch (error) {
      //console.log("Caught error resending link: ", error);
      setCodeIsExpired(false);
      setFormErrorMessage(error.message);
    }
  };

  const accountConfirmedMessage = (
    <React.Fragment>
      <SuccessMessage
        title={sentNewCode ? "Success" : "Account Confirmed"}
        subtitle={
          sentNewCode
            ? "A new link is on the way!"
            : "Please log in to your new account to finish setting it up!"
        }
        hideIcon={!sentNewCode}
      ></SuccessMessage>
      {sentNewCode ? null : (
        <div className={classes.ButtonContainer}>
          <Button
            size="lg"
            className={classes.ToLoginButton}
            onClick={() => {
              //console.log("clicked back to login");
              history.push("/auth");
            }}
          >
            To Login
          </Button>
        </div>
      )}
    </React.Fragment>
  );
  const errorMessage = (
    <React.Fragment>
      <ErrorMessage
        title="Uh oh!"
        subtitle={
          codeIsExpired
            ? "It looks like your verification link has expired.."
            : "For some reason we could not verify your account."
        }
        hideIcon={codeIsExpired}
      ></ErrorMessage>
      {codeIsExpired ? (
        <div className={classes.ButtonContainer}>
          <Button
            size="lg"
            className={classes.ResendLinkButton}
            onClick={resendLinkClicked}
          >
            Resend Link
          </Button>
        </div>
      ) : null}
    </React.Fragment>
  );

  const loadingMessage = (
    <div className={classes.LoadingIconContainer}>
      <Spinner animation="border" size="lg" role="status" aria-hidden="true" />
    </div>
  );

  return isLoading
    ? loadingMessage
    : formErrorMessage
    ? errorMessage
    : accountConfirmedMessage;
}
