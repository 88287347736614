import { apiGet } from "../../shared/apiWrapper";
import * as authActions from "./auth";

export const LOAD_MY_DATA = "GET_MY_DATA";

const UNAUTHED_ERROR_MESSAGE = "No current user";

export const loadMyData = () => {
  return async (dispatch) => {
    try {
      const response = await apiGet("example-api-name", "/example-authorized");
      dispatch({ type: LOAD_MY_DATA, data: response.data });
    } catch (error) {
      //console.log("amplify api get error: ", error);
      if (error === UNAUTHED_ERROR_MESSAGE) {
        dispatch(authActions.setSignedOut());
      }
      throw error;
    }
  };
};
