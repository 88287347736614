export default {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://a9mhsra1fe.execute-api.us-east-1.amazonaws.com/prod",
    API_NAME: "holyminute-prod",
    API_KEY: "3yorEHi8fL45TlSgqg1G87o6MacvZPhq7Tqn8dF6",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_9AOVVpNrN",
    APP_CLIENT_ID: "2pdvhbs4sek7rr8sp0qlda3ujq",
    IDENTITY_POOL_ID: "us-east-1:d4a88591-f5c5-43f2-9dd5-72a113a07988",
  },
  loginTitle: "Holy Minute",
  platformDescription:
    "a Religious platform designed to connect the Church and the faithful in prayer.",
  useLogs: false,
  stripePk:
    "pk_live_51GxAMTK34k1knMFTGs13qMREUedrW0JYkV0cZ3yzG5caOuPEQna5IdsTRPTVfqGPqS6QGjzaMwaG5zw9c4unhckA00uo4th2D3",
  // stripePk:
  //   "pk_test_51GxAMTK34k1knMFTjPHEDiaKMs54IHcKKALeAD2jAJZlS2NExu9IUTLbtFLUqWr9vLu2ArkWQT4Kfr7CsHywi9ti00Ik1vUliu",
  useLiveStripe: true,
};
