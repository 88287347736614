import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faPlus,
  faMinus,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { apiPostUnauthed } from "../../shared/apiWrapper";
import { Spinner, Button } from "react-bootstrap";

import classes from "./Listen.module.scss";

import dev_config from "../../config_dev";
import prod_config from "../../config_prod";
const config = dev_config.useProd ? prod_config : dev_config;

const getUpdatedPriestForName = async (priestName) => {
  try {
    const searchResult = await apiPostUnauthed(
      config.apiGateway.API_NAME,
      `/priests/search`,
      {
        name: priestName ? priestName.trim().toLowerCase() : "",
      }
    );

    if (
      searchResult &&
      searchResult.priest &&
      Object.keys(searchResult.priest).length > 0
    ) {
      const today = new Date();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const year = today.getFullYear();

      const updatedPriest = await apiPostUnauthed(
        config.apiGateway.API_NAME,
        "/refreshed-priest",
        {
          userId: searchResult.priest.userId,
          dayKey: `${month}-${day}-${year}`,
        }
      );

      //console.log("have updated priest: ", JSON.stringify(updatedPriest));

      return updatedPriest;
    } else {
      throw new Error();
    }
  } catch (err) {
    //console.log("caught error loading prayer: ", err);
    throw err;
  }
};

const Listen = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [urlExpiration, setUrlExpiration] = useState(null);
  const [prayer, setPrayer] = useState(null);
  const [hasTriedRefresh, setHasTriedRefresh] = useState(false);

  useEffect(() => {
    //console.log("fetching");
    const loadPriest = async () => {
      try {
        // const updatedPriest = {
        //   prayer: {
        //     creationMillis: 1601846361835,
        //     localRecordingPath:
        //       "/data/user/0/com.holyminute/files/1601846201215_recording.aac",
        //     prayerName: "Monday of the 27th Week",
        //     prayerId: "880fce50-43de-40df-b08c-9e9a4d1d06ff",
        //     ownerId: "d26b1ed5-85d9-416d-9da3-1191cd638beb",
        //     lengthSecs: 125,
        //     url:
        //       "https://holyminute-prayers-bcket-prod.s3.amazonaws.com/public/d26b1ed5-85d9-416d-9da3-1191cd638beb/mp3/mcc_school_mass_5f322494-cf6e-403c-a171-e963d3546d6a.mp3?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA4ABFFTDSOHVCQZPN%2F20201006%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20201006T134943Z&X-Amz-Expires=300&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEMb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIGcU9C%2Fq0wA1tPCScxq3xaz1fFB0o1p6dBxuE8o1vlxJAiBBzZoL721weKvL0LvSFgVvWLM%2BmGHcw1BGmIABGq3LdiryAQjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAEaDDgyNDcxMTY4MjI3NiIMBZtxbjf9xbows19WKsYBgMuLP2gWnDP%2BtFnsgCeGRtwEvnz3NoHYWTF3oQFBNwYpOxzCr04OVBidX7asBbt1LUPWLzd9ZudFNWFuZKrfYq5HZUc5fxAXkoEfvlbmAPf5cAPhh0Jq5weQ4SQd1Tl5IOsJbZp8opts9B%2FKdXP%2BQ3QwgNzSxOcgi81wgdK%2FqpdR0mKP1vZUMFqZwZQR6MxGi4UvPOoqHxhGa01N%2Fqlkbf0%2FxJ6trm64P5GFyDWjG1kxmh4sFXCI2GeGP%2BwfOT76RK3x%2B3%2BuMNjn8fsFOuEBUa2KjvcXW5qPV2v3E6MM7JOb59Wn7FE7svPSwpTSk4S%2FeC4zFy53LTDWM1oMDTWvDYNSw84pMxZVkUw50LZXlXEqi3ZvQKPXFV40Ldn2730eAH2SxsS%2BiI%2BgBNFjNWZ33WFwbM2Px2eHaEf2f5OiqvpwUFBHMXQSxGHfoTIf0FI1mseXNeCXs6PmEWRl%2BpBqmxReSL5i7yF7V2FnCl%2BJbx5WMnriVd0dNuOkqmwDIYgdJLvj9NOeZvcvJSr0o8cZL63B5SzHhefC4H3n0HmYFgqpbhewFO%2Bgw1l7lk1UxtRU&X-Amz-Signature=c672fe2dc365563afc39f94afb1bc683d3fcb546f393426fd7865193c5a6244f&X-Amz-SignedHeaders=host",
        //     urlExpiration: 1601941118309,
        //   },
        // };

        const updatedPriest = await getUpdatedPriestForName(
          props.formattedPriestName
        );
        const updatedPrayer =
          updatedPriest &&
          updatedPriest.prayer &&
          Object.keys(updatedPriest.prayer).length > 0
            ? updatedPriest.prayer
            : null;
        const today = new Date();
        setUrlExpiration(today.getTime() + 300 * 1000);
        setPrayer(updatedPrayer);

        setIsLoading(false);
      } catch (err) {
        //console.log("caught error loading prayer in use effect: ", err);
        setErrorMessage(
          "We're having trouble loading their latest prayer. Sorry!"
        );
      }
    };
    loadPriest();
  }, [props.formattedPriestName]);

  const tryRefresh = async () => {
    //console.log("trying refresh");

    setIsLoading(true);
    setErrorMessage("");
    setPrayer(null);
  };

  const loadingMessage = (
    <div className={classes.LoadingIconContainer}>
      <Spinner animation="border" size="lg" role="status" aria-hidden="true" />
    </div>
  );

  const noPostMessage = (
    <div className={classes.NoPostMessage}>No Post Today</div>
  );

  // const playView = (
  //   <div className={classes.LoadingIconContainer}>
  //     <Spinner animation="border" size="lg" role="status" aria-hidden="true" />
  //   </div>
  // );

  if (!isLoading && !errorMessage && prayer && prayer.url) {
    //console.log("prayer url is: ", prayer.url);
  }

  return (
    <React.Fragment>
      <div
        className={classes.FullScreenMessage}
        style={{
          ...props.style,
          backgroundColor: errorMessage ? "whitesmoke" : "white",
        }}
      >
        {errorMessage ? (
          <React.Fragment>
            <div className={classes.ErrorMessage}>
              Something went wrong trying to load your prayer - sorry!
            </div>
            <div
              onClick={async () => {
                //console.log("clicked retry");
                if (!hasTriedRefresh) {
                  //console.log("retrying...");
                  setHasTriedRefresh(true);
                  setIsLoading(true);
                  setErrorMessage("");
                  try {
                    const updatedPriest = await getUpdatedPriestForName(
                      props.formattedPriestName
                    );

                    const updatedPrayer =
                      updatedPriest &&
                      updatedPriest.prayer &&
                      Object.keys(updatedPriest.prayer).length > 0
                        ? updatedPriest.prayer
                        : null;
                    const today = new Date();
                    setUrlExpiration(today.getTime() + 300 * 1000);
                    setPrayer(updatedPrayer);
                  } catch (err) {
                    //console.log("Caught err in refresh");
                    setErrorMessage(
                      "We're having trouble loading your prayer right now. Sorry!"
                    );
                  }

                  setIsLoading(false);
                } else {
                  //console.log("already retried");
                }
              }}
              className={classes.RetryButton}
            >
              Retry
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={classes.Title} key={20}>
              Pray with{" "}
              <span style={{ textTransform: "capitalize" }}>
                {props.formattedPriestName}
              </span>
            </div>
            <div className={classes.Subtitle} key={21}>
              Click play to hear{" "}
              <span style={{ textTransform: "capitalize" }}>
                {props.formattedPriestName}
              </span>
              's post for today!
            </div>
            {isLoading ? loadingMessage : null}
            {!isLoading && !errorMessage && prayer && prayer.url ? (
              <div
                style={{
                  marginTop: 15,
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
                className={classes.TodaysPrayer}
              >
                <audio
                  src={prayer.url}
                  controls
                  controlsList="nodownload"
                  onError={() => {
                    setErrorMessage(
                      "Something went wrong trying to load your prayer. Sorry!"
                    );
                  }}
                  className={classes.AudioActive}
                />
              </div>
            ) : null}
            {!isLoading && !errorMessage && !prayer ? (
              <div
                style={{
                  marginTop: 7,
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
                className={classes.TodaysPrayer}
              >
                {noPostMessage}
              </div>
            ) : null}

            {/* {props.hideIcon ? null : (
        <div>
          <div className={classes.IconContainer}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "3em" }}
            ></FontAwesomeIcon>
          </div>
        </div>
      )} */}
          </React.Fragment>
        )}

        <div className={`${classes.PoliciesText}`}>
          All use subject to our{" "}
          <a className={`${classes.PolicyLink}`} href="www.holymin.com/#/terms">
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            className={`${classes.PolicyLink}`}
            href="www.holymin.com/#/privacy-policy"
          >
            Privacy Policy
          </a>
        </div>
      </div>
      {!errorMessage ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <a
            href="https://www.holymin.com/#/select-device"
            className={classes.MoreInfoButton}
          >
            More ways to listen
          </a>
          <a
            href="https://www.holymin.com/#/give"
            className={classes.SupportButton}
          >
            Support Holy Minute
          </a>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default Listen;
