export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  let errorMessage = "";

  if (!rules) {
    return { isValid, errorMessage };
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
    errorMessage = isValid ? "" : `${rules.minLength} character minimum`;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
    errorMessage = isValid ? "" : `${rules.maxLength} character maximum`;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
    errorMessage = isValid ? "" : "Must be a valid address";
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
    errorMessage = isValid ? "" : `Must be a number`;
  }

  if (rules.required && value.trim() === "") {
    isValid = false;
    errorMessage = "";
  }

  return { validity: isValid, errorMessage };
};
