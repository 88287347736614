const resetPasswordControls = {
  newPassword: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "New Password",
    },
    value: "",
    validation: {
      required: true,
      minLength: 8,
    },
    valid: false,
    invalidMessage: "",
  },
  confirmNewPassword: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "Confirm New Password",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: false,
    invalidMessage: "",
  },
};

module.exports.RESET_PASSOWORD_CONTROLS = resetPasswordControls;
