import React from "react";

import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

//import classes from "./HomePage.module.scss";

const HomePage = (props) => {
  return (
    <div>
      {/* <h1>Home Page</h1> */}
      {/* <ProtectedContent></ProtectedContent> */}
      <WelcomeMessage></WelcomeMessage>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LogoutButton></LogoutButton>
      </div>
    </div>
  );
};

export default withAuth(HomePage);
