import React from "react";
import classes from "./CreatorPreinfoPage.module.scss";

const FAQS = [
  {
    question: "How do I post on Holy Minute?",
    answer: (
      <div>
        Posting on Holy Minute is easy to do! All you need is the Holy Minute
        app, available through the Apple App Store or Google Play. The app makes
        it simple to record your prayers or reflections and schedule your posts.
        <br></br>
        <br></br>In order to get started making your account, you can click the
        "Sign Up" button located above, or download the Holy Minute app and sign
        up from there!
      </div>
    ),
  },
  {
    question: "What can I post on Holy Minute?",
    answer: (
      <div>
        We designed Holy Minute to help people enter into a few minutes of grace
        in their daily lives. Within that broad goal, the sky is the limit! Your
        Holy Minute recordings could be traditional prayers, reflections on the
        day’s readings, passages from the Bible, reflections on the lives of the
        saints, or whatever you feel would be the best form of prayer for the
        day. <br></br>One important rule: due to technical restrictions,
        recordings have a time limit of 4 minutes.{" "}
      </div>
    ),
  },
  {
    question: "How do people listen to what I have posted on Holy Minute?",
    answer: (
      <div>
        Anyone with a smartphone can listen to you on the Holy Minute app by
        searching for you using your name. Anyone with internet access can
        listen to your latest post using your Holy Minute web link. Anyone with
        an Alexa- or Google Assistant-enabled device can listen to your prayers
        and reflections just by asking for you out loud!
      </div>
    ),
  },
  {
    question: "Who can listen to Holy Minute?",
    answer: (
      <div>
        We built Holy Minute to allow as much listener access as possible. Your
        listeners can listen to your latest post by following you in the Holy
        Minute app or by finding you on the Holy Minute website using your web
        link. People can also listen on their Alexa- and Google-Assistant
        enabled devices (think: smart speakers, like the Amazon Echo) just by
        asking for you by name. <br></br>
        <br></br>Short answer: everyone!
      </div>
    ),
  },
  {
    question: "How much does Holy Minute cost?",
    answer: (
      <div>
        Holy Minute is provided free of charge for all users! We are able to
        support the cost of the platform through free-will contributions from
        our listeners, which can be made online and through our website.
      </div>
    ),
  },
  {
    question: "How do I help my listeners find me on Holy Minute?",
    answer: (
      <div>
        We have created materials to help you tell people about Holy Minute!
        Once you log in to your account, you will have access to a sample
        announcement to let people know how to listen to you and directing them
        to resources with everything they will need to spend a Holy Minute with
        you.
        <br></br>
        <br></br>Every priest/religious also gets their own Holy Minute web
        link. This brings visitors to your Holy Minute post for the day, hosted
        on our website. You can use this link on your website or wherever is
        convenient for your listeners to help them start praying with you!
      </div>
    ),
  },
  {
    question: "Do I have to post on Holy Minute every day?",
    answer: (
      <div>
        Definitely not! The way you use Holy Minute is completely up to you and
        what is best for yourself and your listeners. We have designed Holy
        Minute to gracefully handle different patterns of use. The app allows
        you to schedule posts in advance, schedule the same recording for
        multiple days, or just not post anything at all. If you want to post
        something new for each day, great! If not, that is fine as well - it
        will work equally well with weekly or even less frequent updates.
      </div>
    ),
  },
  {
    question: "I have a question you didn't answer. What should I do?",
    answer: (
      <div className={classes.WrappedAnywhere}>
        We would love to hear from you! Feel free to contact us at
        holymin@harbourviewtechnologies.com with any questions or feedback.
      </div>
    ),
  },
];
export { FAQS };
