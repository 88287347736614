import { LOAD_USER, LOAD_USER_FAILED, SET_PAID } from "../actions/user.js";
import { LOGOUT } from "../actions/auth.js";

import { updateObject } from "../../shared/utility";

const initialState = {
  user: {},
  hasAttemptedUserLoad: false,
  hasFailedUserLoad: false,
};

export default (state = initialState, action) => {
  //console.log(action);
  switch (action.type) {
    case LOAD_USER:
      //console.log("setting freshly loaded user");
      return updateObject(state, {
        user: action.user,
        hasAttemptedUserLoad: true,
        hasFailedUserLoad: false,
      });
    case LOAD_USER_FAILED:
      //console.log("failed to load user");
      return updateObject(state, {
        user: {},
        hasAttemptedUserLoad: true,
        hasFailedUserLoad: true,
      });
    case SET_PAID:
      return updateObject(state, {
        user: updateObject(state.user, { paid: action.paid }),
      });
    case LOGOUT:
      return initialState;
  }
  return state;
};
