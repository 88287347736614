import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import LoginButton from "../../components/LoginButton/LoginButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebookSquare,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./ListenInAppInstructions.module.scss";

const ListenInAppInstructions = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <LoginButton></LoginButton>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Holy Minute
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            Listen on iPhone or Android
            <div className={classes.SubQuestionText}>
              Using the Holy Minute app{" "}
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "block",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                  maxWidth: "85%",
                }}
              >
                <div className={`${classes.InstructionText}`}>
                  <div>
                    1. Download{" "}
                    <span className={classes.SpokenWords}>Holy Minute</span>{" "}
                    from the{" "}
                    <a
                      className={classes.Link}
                      href="https://apps.apple.com/us/app/id1518023204"
                    >
                      App Store
                    </a>{" "}
                    or{" "}
                    <a
                      className={classes.Link}
                      href="https://play.google.com/store/apps/details?id=com.holyminute"
                    >
                      Google Play Store
                    </a>
                    .
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    2. When you open the app, select that you want to{" "}
                    <span className={classes.SpokenWords}>Listen</span> in order
                    to navigate to the listening screen.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    3. Tap the{" "}
                    <span className={classes.SpokenWords}>Follow</span> button
                    and search for a priest or religious by the full name they
                    are using on Holy Minute. Tap the plus button next to a
                    priest/religious in order to follow them and add them to
                    your listening screen. This only needs to be done the first
                    time you listen to a priest or religious.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    4. Once you are following a priest or religious, it will
                    appear on your listening screen. To hear what they have
                    posted for the day, tap the play button to the right of
                    their name on your listening screen. Priests and religious
                    with nothing posted will not have a play button to press -
                    instead, they will have a green message indicating no post
                    is available.
                    <br></br>
                    <br></br>
                    {"  "}
                    <span className={classes.SpokenWords}>Tips{"  "}</span>{" "}
                    <br></br>
                    <br></br>
                    {/* For an easier way to add a class, just press{" "}
                    <span className={classes.SpokenWords}>SCAN QR</span> on the
                    Add Class screen and point your camera at the QR code
                    provided to you by your teacher.
                    <br></br> */}
                    To pray along with a selection of classic prayers such as
                    the Our Father and Glory Be, just tap the down arrow located
                    to the right of the name of the priest or religious you want
                    to pray with! You will then be able to see which traditional
                    prayers they have posted.
                    <br></br>
                    <br></br>
                    If you want to refresh your prayers to make sure you are
                    looking at the most recent posts, just pull down on your
                    followed list.
                    <br></br>
                    <br></br>
                    For more ideas on who is available to follow, just visit us
                    on{" "}
                    <a
                      className={classes.Link}
                      href="https://www.instagram.com/holyminute/"
                    >
                      Instagram
                    </a>{" "}
                    or{" "}
                    <a
                      className={classes.Link}
                      href="https://www.facebook.com/Holy-Minute-109285774177742"
                    >
                      Facebook
                    </a>
                    !<br></br>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </div>
        )}
      </Transition>
      <div
        style={{
          width: "!00%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          // border: "solid",
          // borderWidth: 1,
          paddingTop: "20px",
          paddingBottom: "20px",
          backgroundColor: "whitesmoke",
        }}
      >
        <div
          style={{
            fontSize: 10,
            width: 220,
            fontWeight: "bold",
            //borderWidth: 1,
            //border: "solid",
            //borderColor: "red",
            //borderRadius: 15,
            //backgroundColor: "#9A66BC",
            //color: "white",
            color: "#444444",
          }}
        >
          Discover more priests and religous to pray with on Holy Minute:
          <div className={`${classes.IconContainer} ${classes.ErrorIcon}`}>
            <a href="https://www.instagram.com/holyminute/">
              <FontAwesomeIcon
                icon={faInstagram}
                style={{ fontSize: 35, margin: 5, color: "#6d219e" }}
                className={`${classes.SocialLink}`}
              ></FontAwesomeIcon>
            </a>
            <a href="https://www.facebook.com/Holy-Minute-109285774177742">
              <FontAwesomeIcon
                icon={faFacebookSquare}
                style={{ fontSize: 35, margin: 5, color: "#6d219e" }}
                className={`${classes.SocialLink}`}
              ></FontAwesomeIcon>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListenInAppInstructions;
