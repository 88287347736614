import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import { withAuth } from "../../hoc/Auth/Auth";
import * as userActions from "../../store/actions/user";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { apiGet } from "../../shared/apiWrapper";
import classes from "./AccountHomePage.module.scss";
import dev_config from "../../config_dev";
import prod_config from "../../config_prod";
const config = dev_config.useProd ? prod_config : dev_config;

const AccountHomePage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const { user } = useSelector((state) => state.userStore);

  const [isLoadingBillingPage, setIsLoadingBillingPage] = useState(false);

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const loadUserAsync = async () => {
  //     if (!hasAttemptedUserLoad) {
  //       await dispatch(userActions.loadUser());
  //     }
  //   };
  //   loadUserAsync();
  // }, [hasAttemptedUserLoad]);

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  const bodyContent = (
    <React.Fragment>
      {" "}
      <span style={{ color: "#444444" }}>Welcome to</span>{" "}
      <span style={{ fontWeight: "bold" }}>Holy Minute</span>
      <div className="row">
        <div className={`col-sm-12 col-lg-6 col-md-6 ${classes.LeftRowItem}`}>
          <div
            onClick={() => {
              //console.log("clicked get started");
              history.push("/get-started");
            }}
            className={`${classes.AnswerText}`}
          >
            <div style={{ marginLeft: 27, marginRight: 27 }}>Get Started</div>
            <div className={classes.AnswerSubtext}>
              Learn how to start posting your prayers and reflections to Holy
              Minute
            </div>
            <div className={classes.AnswerSubicon}>
              {/* Learn more about posting my prayers on Holy Minute */}
              <FontAwesomeIcon
                icon={faArrowCircleRight}
                style={{ fontSize: "25", margin: "5px" }}
              ></FontAwesomeIcon>
            </div>
          </div>
        </div>
        <div className={`col-sm-12 col-lg-6 col-md-6 ${classes.RightRowItem}`}>
          <div
            onClick={() => {
              //console.log("clicked spread the word");
              //setRedirectUrl("/auth");
              history.push("/spread-the-word");
            }}
            className={`${classes.AnswerText}`}
          >
            <div>Spread the Word</div>
            <div className={classes.AnswerSubtext}>
              How to let your listeners know you are on Holy Minute and help
              them find you
            </div>
            <div className={classes.AnswerSubicon}>
              {/* Learn more about posting my prayers on Holy Minute */}
              <FontAwesomeIcon
                icon={faArrowCircleRight}
                style={{ fontSize: "25", margin: "5px" }}
              ></FontAwesomeIcon>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className={`col-sm-12 col-lg-6 col-md-6 ${classes.LeftRowItem}`}>
          <div
            onClick={() => {
              //console.log("clicked nominate");
              history.push("/nominate");
            }}
            className={`${classes.AnswerText}`}
          >
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <div>Nominate</div>
            </div>
            <div className={classes.AnswerSubtext}>
              Know someone who wants to get on Holy Minute? Let us know we can
              trust them!
            </div>
            <div className={classes.AnswerSubicon}>
              {/* Learn more about posting my prayers on Holy Minute */}
              <FontAwesomeIcon
                icon={faArrowCircleRight}
                style={{ fontSize: "25", margin: "5px" }}
              ></FontAwesomeIcon>
            </div>
          </div>
        </div>
        <div className={`col-sm-12 col-lg-6 col-md-6 ${classes.RightRowItem}`}>
          <div
            onClick={async () => {
              setIsLoadingBillingPage(true);
              //console.log("clicked my account");
              //setRedirectUrl("/auth");
              //history.push("/manage-account");
              const session = await apiGet(
                config.apiGateway.API_NAME,
                "/user/self/billing-session"
              );
              //console.log("got billing session: ", session);
              try {
                window.location.href = session.session.url;
              } catch (err) {
                //console.log("caught error showing billing page: ", err);
                history.push("/manage-account");
              }
            }}
            className={`${classes.AnswerText}`}
          >
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <div>My Account</div>
            </div>
            {isLoadingBillingPage ? null : (
              <div className={classes.AnswerSubtext}>
                View your account details, manage your payment options, or
                cancel your account
              </div>
            )}

            <div className={classes.AnswerSubicon}>
              {/* Learn more about posting my prayers on Holy Minute */}
              {isLoadingBillingPage ? null : (
                <FontAwesomeIcon
                  icon={faArrowCircleRight}
                  style={{ fontSize: "25", margin: "5px" }}
                ></FontAwesomeIcon>
              )}
            </div>
            {isLoadingBillingPage ? (
              <div class={classes.SpinnerDiv}>
                <Spinner
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  const loadingSpinner = (
    <Spinner animation="border" size="lg" role="status" aria-hidden="true" />
  );

  let contentToDisplay = bodyContent;
  // if (!hasAttemptedUserLoad) contentToDisplay = loadingSpinner;
  // if (hasFailedUserLoad)
  //   contentToDisplay = (
  //     <ErrorMessage
  //       title="Uh oh!"
  //       subtitle={
  //         "We are having trouble loading your user profile. Please try again. If the problem persists, be sure to let us know so we can help!"
  //       }
  //     ></ErrorMessage>
  //   );
  // if (hasAttemptedUserLoad && !hasFailedUserLoad) {
  //  //console.log("we have a user, here's the data: ", user);
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Holy Minute
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            {contentToDisplay}
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 50,
              }}
            >
              <LogoutButton></LogoutButton>
            </div> */}
          </div>
        )}
      </Transition>
    </div>
  );
};

export default withAuth(AccountHomePage);
