const loginControls = {
  1: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Email",
    },
    value: "",
    validation: {
      required: true,
      isEmail: true,
    },
    valid: false,
    invalidMessage: "",
  },
  2: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "Password",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: false,
    invalidMessage: "",
  },
};

const signUpControls = {
  3: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Email",
    },
    value: "",
    validation: {
      required: true,
      isEmail: true,
    },
    valid: false,
    invalidMessage: "",
  },
  4: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "Password",
    },
    value: "",
    validation: {
      required: true,
      minLength: 8,
    },
    valid: false,
    invalidMessage: "",
  },
  5: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "Confirm Password",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: false,
    invalidMessage: "",
  },
};

module.exports.LOGIN_CONTROLS = loginControls;
module.exports.SIGNUP_CONTROLS = signUpControls;
