import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import LoginButton from "../../components/LoginButton/LoginButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebookSquare,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./IphoneInstructionsPage.module.scss";

const IphoneInstructionsPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <LoginButton></LoginButton>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Holy Minute
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            Listen on iPhone:
            {/* <div className={classes.SubQuestionText}>
              Echo, Echo Dot, Echo Show, any Alexa-enabled device{" "}
            </div> */}
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "block",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                  maxWidth: "85%",
                }}
              >
                <div className={`${classes.InstructionText}`}>
                  <div>
                    1. Download, open, and sign in to the{" "}
                    <a
                      className={classes.Link}
                      href="https://apps.apple.com/us/app/amazon-alexa/id944011620"
                    >
                      Amazon Alexa App
                    </a>
                    . You will need to create an Amazon account if you do not
                    have one already.{" "}
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    2. The app will then walk you through getting Alexa set up
                    on your phone. When prompted, be sure to grant Alexa
                    permission to access your location while using the app. This
                    will allow us to make sure we are getting you the right
                    prayer on the right day based on your timezone.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    3. To talk to Alexa, press the round blue button at the top
                    of the screen
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    4. Enable the Holy Minute skill by pressing the round blue
                    button and saying,{" "}
                    <span className={classes.SpokenWords}>
                      "Enable Holy Minute."
                    </span>{" "}
                    This step only needs to be done{" "}
                    <span className={classes.SpokenWords}>once</span>, the first
                    time you use Holy Minute.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    5. To hear someone's Holy Minute post for the day, press the
                    blue button and say{"  "}
                    <span className={classes.SpokenWords}>
                      "Open Holy Minute."
                    </span>
                    {"  "}
                    When Alexa asks you who you would like to pray with, say the
                    name of the priest or religious you are looking for.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    6. To pray a traditional prayer, press the blue button and
                    say{" "}
                    <span className={classes.SpokenWords}>
                      "Ask Holy Minute to pray{"  "}
                      <span style={{ fontStyle: "italic" }}>~prayer name~</span>
                      {"  "}
                      with <span style={{ fontStyle: "italic" }}>~name~</span>."
                    </span>
                    <br></br>
                    Supported traditional prayers include the Our Father, Hail
                    Mary, Glory Be, Act of Contrition, Apostles Creed, Nicene
                    Creed, and a mealtime prayer.
                    <br></br>
                    <br></br>
                    For more detailed instructions, please see our{" "}
                    <a
                      className={classes.Link}
                      href="https://www.amazon.com/Harbourview-Technologies-LLC-Holy-Minute/dp/B08B3YT73G"
                    >
                      Alexa Skill store page
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </div>
        )}
      </Transition>
      <div
        style={{
          width: "!00%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          // border: "solid",
          // borderWidth: 1,
          paddingTop: "20px",
          paddingBottom: "20px",
          backgroundColor: "whitesmoke",
        }}
      >
        <div
          style={{
            fontSize: 10,
            width: 220,
            fontWeight: "bold",
            //borderWidth: 1,
            //border: "solid",
            //borderColor: "red",
            //borderRadius: 15,
            //backgroundColor: "#9A66BC",
            //color: "white",
            color: "#444444",
          }}
        >
          Discover more priests and religous to pray with on Holy Minute:
          <div className={`${classes.IconContainer} ${classes.ErrorIcon}`}>
            <a href="https://www.instagram.com/holyminute/">
              <FontAwesomeIcon
                icon={faInstagram}
                style={{ fontSize: 35, margin: 5, color: "#6d219e" }}
                className={`${classes.SocialLink}`}
              ></FontAwesomeIcon>
            </a>
            <a href="https://www.facebook.com/Holy-Minute-109285774177742">
              <FontAwesomeIcon
                icon={faFacebookSquare}
                style={{ fontSize: 35, margin: 5, color: "#6d219e" }}
                className={`${classes.SocialLink}`}
              ></FontAwesomeIcon>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IphoneInstructionsPage;
