import React, { useState } from "react";

//import classes from "./TextInput.module.scss";
import "./TextInput.scss";

const TextInput = (props) => {
  //console.log("rendering TEXTINPUT");

  const [active, setActive] = useState((props.locked && props.active) || false);

  const fieldClassName = `field ${
    (props.locked ? false : active || props.value) && "active"
  } ${props.locked && !active && "locked"}`;

  return (
    <div className={fieldClassName}>
      {active &&
        props.value &&
        props.predicted &&
        props.predicted.includes(props.value) && (
          <p className="predicted">{props.predicted}</p>
        )}
      <input
        id={props.id}
        type={props.type || "Text"}
        value={props.value}
        placeholder={props.label}
        onChange={(event) => {
          props.setValue(event);
        }}
        onFocus={() => {
          if (!props.locked) {
            setActive(true);
            props.clearFormSubmissionError && props.clearFormSubmissionError();
          }
        }}
        onBlur={() => {
          !props.locked && setActive(false);
        }}
        autoComplete="off"
      />
      <label htmlFor={1} className={props.error ? "error" : ""}>
        {props.error ? `${props.label}: ${props.error}` : props.label}
      </label>
    </div>
  );
};

export default TextInput;
