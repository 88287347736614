import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./CreatorTutorialPage.module.scss";

const CreatorTutorialPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Holy Minute
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            Get Started
            <div className={classes.SubQuestionText}>
              How to post your prayers and reflections on Holy Minute{" "}
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "block",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                }}
              >
                <div className={`${classes.InstructionText}`}>
                  <div>
                    1. Download the Holy Minute app from the{" "}
                    <a
                      className={classes.Link}
                      href="https://apps.apple.com/us/app/id1518023204"
                    >
                      Apple App Store
                    </a>{" "}
                    or the{" "}
                    <a
                      className={classes.Link}
                      href="https://play.google.com/store/apps/details?id=com.holyminute"
                    >
                      Google Play Store
                    </a>{" "}
                    .
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    2. Sign in with your Holy Minute account.<br></br>
                    The app includes a walkthrough at the beginning - the
                    following is largely a repetition of the information
                    included there!
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    3. Press the purple microphone button on the{" "}
                    <span className={classes.SpokenWords}>Pray</span> tab to
                    record a prayer or reflection. Recordings can be a maximum
                    of 4 minutes in length due to technical restrictions.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    4. To post a prayer that you have recorded, go to the{" "}
                    <span className={classes.SpokenWords}>Schedule</span> tab
                    and press the plus button next to the day you want to post
                    it to. You can also set a default prayer on this screen,
                    which will be the recording that is used for days when you
                    don't have a prayer posted to your schedule. <br></br>The
                    recordings that you post on your schedule tab are what your
                    listeners will hear when they say things like, "Ask Holy
                    Minute to pray with (your name)."
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    5. Listeners can also ask to pray any one of a set of
                    traditional prayers on Holy Minute. You can set recordings
                    for the traditional prayers on the{" "}
                    <span className={classes.SpokenWords}>Classics</span> tab.{" "}
                    <br></br>These are what your listeners will hear when they
                    say things like, "Ask Holy Minute to pray with an Our Father
                    with (your name)."
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    <span
                      className={classes.SpokenWords}
                      style={{ marginLeft: 0 }}
                    >
                      Tips:
                    </span>{" "}
                    <br></br>
                    <br></br>Don't worry if you can't post something for every
                    day or every classic prayer! Holy Minute is built to allow
                    for that, and will simply let the user know that you haven't
                    posted anything yet. Setting a default prayer can also help
                    make sure that your listeners will always have something to
                    pray with.
                    <br></br>
                    <br></br>If you have any questions, don't hesitate to email
                    us!
                  </div>
                </div>
                {/* <div className={`${classes.InstructionText}`}>
                  <div>
                    1. Enable the skill on your Alexa device by saying,{" "}
                    <span className={classes.SpokenWords}>
                      "Alexa, enable Holy Minute."
                    </span>
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    2. To hear someone's Holy Minute post for the day, say{"  "}
                    <span className={classes.SpokenWords}>
                      "Alexa, ask Holy Minute to pray with{"  "}
                      <span style={{ fontStyle: "italic" }}>~name~</span>."
                    </span>
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    3. To pray a traditional prayer, say{" "}
                    <span className={classes.SpokenWords}>
                      "Alexa, ask Holy Minute to pray{"  "}
                      <span style={{ fontStyle: "italic" }}>~prayer name~</span>
                      {"  "}
                      with <span style={{ fontStyle: "italic" }}>~name~</span>."
                    </span>
                    <br></br>
                    Supported traditional prayers include the Our Father, Hail
                    Mary, Glory Be, Act of Contrition, Apostles Creed, Nicene
                    Creed, and a mealtime prayer.
                    <br></br>
                    <br></br>
                    For more detailed instructions, please see our{" "}
                    <a
                      className={classes.Link}
                      href="https://www.amazon.com/Harbourview-Technologies-LLC-Holy-Minute/dp/B08B3YT73G"
                    >
                      Alexa Skill store page
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          // </div>
        )}
      </Transition>
    </div>
  );
};

export default withAuth(CreatorTutorialPage);
