import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import * as authActions from "../../store/actions/auth";
import * as userActions from "../../store/actions/user";
import classes from "./Auth.module.scss";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";

export const withAuth = (BaseComponent) => (props) => {
  const dispatch = useDispatch();
  const isAuthed = useSelector((state) => !!state.authStore.authorized);
  const isCheckingSession = useSelector(
    (state) => !!state.authStore.isCheckingSession
  );
  const { user, hasAttemptedUserLoad, hasFailedUserLoad } = useSelector(
    (state) => state.userStore
  );

  useEffect(() => {
    const checkLoggedIn = async () => {
      if (isCheckingSession) {
        try {
          await dispatch(authActions.refreshSession());
        } catch (e) {
          //console.log("Caught error dispatching session refresh action: ", e);
        }
      }
    };
    checkLoggedIn();
  }, [isCheckingSession, dispatch]);

  useEffect(() => {
    const loadUserAsync = async () => {
      if (!hasAttemptedUserLoad) {
        //console.log("loading user...");
        await dispatch(userActions.loadUser());
      }
    };
    loadUserAsync();
  }, [hasAttemptedUserLoad]);

  const loadingMessage = (
    <div className={classes.LoadingIconContainer}>
      <Spinner animation="border" size="lg" role="status" aria-hidden="true" />
    </div>
  );

  if (isCheckingSession) {
    return loadingMessage;
  }
  if (isAuthed) {
    if (!hasAttemptedUserLoad) {
      return loadingMessage;
    }
    if (hasFailedUserLoad) {
      return (
        <ErrorMessage
          title="Uh oh!"
          subtitle={
            "We are having trouble loading your user profile. Please try again. If the problem persists, be sure to let us know so we can help!"
          }
        ></ErrorMessage>
      );
    }
    //console.log("have user data: ", user);
    if (!user.hasPaid || user.hasPaid === "false") {
      return <Redirect to={"/collect-payment"}></Redirect>;
    }
    return (
      <React.Fragment>
        <LogoutButton></LogoutButton>
        <BaseComponent {...props}></BaseComponent>
      </React.Fragment>
    );
  } else {
    return <Redirect to={"/auth"}></Redirect>;
  }
};
