import React, { useEffect } from "react";
import { writeLog } from "../../shared/logger";
import { NavLink } from "react-router-dom";

//import classes from './ProtectedContent.module.scss'
import { Button } from "react-bootstrap";
import { withAuth } from "../../hoc/Auth/Auth";

import { useSelector, useDispatch } from "react-redux";
import * as exampleActions from "../../store/actions/exampleStore";
import * as authActions from "../../store/actions/auth";

const ProtectedContent = (props) => {
  const dispatch = useDispatch();

  const protectedData = useSelector((state) => state.exampleStore.data);
  useEffect(() => {
    writeLog("current data from api: ", protectedData);
  }, [protectedData]);

  const loadDataAsync = async () => {
    writeLog("called load data async");

    try {
      await dispatch(exampleActions.loadMyData());
    } catch (error) {
      //console.log("could not make api call: ", error);
    }
  };

  const logoutAsync = async () => {
    try {
      const response = await dispatch(authActions.logout());
      writeLog("have signout response: ", response);
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <div style={{ margin: "20px" }}>
      <h1>{props.title}</h1>
      <Button onClick={loadDataAsync} variant="primary">
        Load Data
      </Button>
      <Button
        onClick={logoutAsync}
        variant="primary"
        style={{ margin: "20px" }}
      >
        Logout
      </Button>
      <NavLink to="/home1">Home1</NavLink>
      <NavLink to="/home2">Home2</NavLink>
    </div>
  );
};

export default withAuth(ProtectedContent);
