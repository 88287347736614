import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import classes from "./SuccessMessage.module.scss";

const SuccessMessage = (props) => {
  return (
    <div className={classes.FullScreenMessage} style={{ ...props.style }}>
      <div className={classes.Title} style={{ ...props.titleStyle }} key={20}>
        {props.title}
      </div>
      <div className={classes.Subtitle} key={21}>
        {props.subtitle}
      </div>
      {props.hideIcon ? null : (
        <div>
          <div className={classes.IconContainer}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "3em" }}
            ></FontAwesomeIcon>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuccessMessage;
