import { LOGIN, LOGOUT, REFRESH_SESSION } from "../actions/auth.js";
import { writeLog } from "../../shared/logger";

import { updateObject } from "../../shared/utility";

const initialState = {
  authorized: false,
  isCheckingSession: true,
  isVerified: false,
};

export default (state = initialState, action) => {
  //console.log(action);
  switch (action.type) {
    case LOGIN:
      writeLog("setting the auth state to logged in: ", action.isVerified);
      return updateObject(state, {
        authorized: true,
        isCheckingSession: false,
        isVerified: action.isVerified,
      });
    case LOGOUT:
      writeLog("setting the auth state to logged out");
      return updateObject(state, {
        authorized: false,
        isCheckingSession: false,
        isVerified: false,
      });
    case REFRESH_SESSION:
      writeLog("setting isCheckingSession to false");
      return updateObject(state, {
        isCheckingSession: false,
        authorized: true,
      });
    default:
      return state;
  }
};
