import { Auth, API } from "aws-amplify";
import { writeLog } from "./logger";
import dev_config from "../config_dev";
import prod_config from "../config_prod";
const config = dev_config.useProd ? prod_config : dev_config;

export const apiGet = async (apiName, path) => {
  try {
    const tokens = await Auth.currentSession();

    const idToken =
      tokens && tokens.idToken && tokens.idToken.jwtToken
        ? tokens.idToken.jwtToken
        : "";

    let init = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      response: true,
    };

    writeLog("calling api using params: ", init);

    const response = await API.get(apiName, path, init);
    writeLog("got data from api: ", response);
    return response.data;
  } catch (error) {
    //console.log("could not make api call: ", JSON.stringify(error));
    throw error;
  }
};
export const apiPost = async (apiName, path, body) => {
  try {
    const tokens = await Auth.currentSession();

    const idToken =
      tokens && tokens.idToken && tokens.idToken.jwtToken
        ? tokens.idToken.jwtToken
        : "";

    let init = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: body,
      response: true,
    };

    writeLog("calling api using params: ", init);

    const response = await API.post(apiName, path, init);
    writeLog("got data from api: ", response);
    return response.data;
  } catch (error) {
    //console.log("could not make api call: ", JSON.stringify(error));
    throw error;
  }
};

export const apiPostUnauthed = async (apiName, path, body) => {
  try {
    let init = {
      headers: {
        // Authorization: `Bearer ${idToken}`,
        "x-api-key": config.apiGateway.API_KEY,
        "Content-Type": "application/json",
      },
      body: body,
      response: true,
    };

    //console.log('calling api using params: ', init);

    const response = await API.post(apiName, path, init);
    //console.log("got data from api: ", response);
    return response.data;
  } catch (error) {
    //console.log("could not make api call: ", JSON.stringify(error));
    throw error;
  }
};
