import React from "react";
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  useHistory,
} from "react-router-dom";
import "./App.scss";
import Login from "./containers/Login/Login";
import ProtectedContent from "./containers/ProtectedContent/ProtectedContent";
import HomePage from "./containers/HomePage/HomePage";
import AccountHomePage from "./containers/AccountHomePage/AccountHomePage";
import CollectPaymentPage from "./containers/CollectPaymentPage/CollectPaymentPage";
import SpreadTheWordPage from "./containers/SpreadTheWordPage/SpreadTheWordPage";
import CreatorTutorialPage from "./containers/CreatorTutorialPage/CreatorTutorialPage";
import MyAccountPage from "./containers/MyAccountPage/MyAccountPage";
import NominatePage from "./containers/NominatePage/NominatePage";
import SimpleMarketingPage from "./containers/SimpleMarketingPage/SimpleMarketingPage";
import GivePage from "./containers/GivePage/GivePage";
import ListenPage from "./containers/ListenPage/ListenPage";
import ThanksForGivingPage from "./containers/ThanksForGivingPage/ThanksForGivingPage";
import MarketingLandingPage from "./containers/MarketingLandingPage/MarketingLandingPage";
import LandingPage from "./containers/LandingPage/LandingPage";
import PrivacyPolicyPage from "./containers/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsAndConditionsPage from "./containers/TermsAndConditionsPage/TermsAndConditionsPage";
import IntroSurveyPage from "./containers/IntroSurveyPage/IntroSurveyPage";
import SelectDevicePage from "./containers/SelectDevicePage/SelectDevicePage";
import SelectPhoneTypePage from "./containers/SelectPhoneTypePage/SelectPhoneTypePage";
import AlexaInstructionsPage from "./containers/AlexaInstructionsPage/AlexaInstructionsPage";
import GoogleInstructionsPage from "./containers/GoogleInstructionsPage/GoogleInstructionsPage";
import AndroidInstructionsPage from "./containers/AndroidInstructionsPage/AndroidInstructionsPage";
import IphoneInstructionsPage from "./containers/IphoneInstructionsPage/IphoneInstructionsPage";
import ListenInAppInstructions from "./containers/ListenInAppInstructions/ListenInAppInstructions";
import CreatorPreinfoPage from "./containers/CreatorPreinfoPage/CreatorPreinfoPage";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import AccountConfirmed from "./containers/AccountConfirmed/AccountConfirmed";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import ConfirmationInstructions from "./containers/ConfirmationInstructions/ConfirmationInstructions";
import PreregistrationRequired from "./containers/PreregistrationRequired/PreregistrationRequired";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import collectionsReducer from "./store/reducers/collections";
import authReducer from "./store/reducers/auth";
import exampleReducer from "./store/reducers/exampleStore";
import userReducer from "./store/reducers/userStore";
import dev_config from "./config_dev";
import prod_config from "./config_prod";
const config = dev_config.useProd ? prod_config : dev_config;

const rootReducer = combineReducers({
  collectionsStore: collectionsReducer,
  authStore: authReducer,
  userStore: userReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

const stripePromise = loadStripe(config.stripePk);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <div className="App">
          <header className="App-header">
            <Switch>
              <Route path="/auth" exact render={(props) => <Login></Login>} />
              <Route
                path="/privacy-policy"
                exact
                render={(props) => <PrivacyPolicyPage></PrivacyPolicyPage>}
              />
              <Route
                path="/terms"
                exact
                render={(props) => (
                  <TermsAndConditionsPage></TermsAndConditionsPage>
                )}
              />
              <Route
                path="/marketing"
                exact
                render={(props) => <SimpleMarketingPage></SimpleMarketingPage>}
              />
              <Route
                path="/give"
                exact
                render={(props) => <GivePage></GivePage>}
              />
              <Route
                path="/listen/:priestName"
                exact
                render={(props) => <ListenPage></ListenPage>}
              />
              <Route
                path="/thank-you"
                exact
                render={(props) => <ThanksForGivingPage></ThanksForGivingPage>}
              />
              <Route
                path="/forgot-password"
                exact
                render={(props) => <ForgotPassword></ForgotPassword>}
              />
              <Route
                path="/reset/:code/user/:userName"
                exact
                render={(props) => <ResetPassword></ResetPassword>}
              />
              <Route
                path="/confirm-account/:code/user/:userName"
                exact
                render={(props) => <AccountConfirmed></AccountConfirmed>}
              />
              <Route
                path="/confirmation-required"
                exact
                render={(props) => (
                  <ConfirmationInstructions></ConfirmationInstructions>
                )}
              />
              <Route
                path="/select-device"
                exact
                render={(props) => <SelectDevicePage></SelectDevicePage>}
              />
              <Route
                path="/alexa-instructions"
                exact
                render={(props) => (
                  <AlexaInstructionsPage></AlexaInstructionsPage>
                )}
              />
              <Route
                path="/google-instructions"
                exact
                render={(props) => (
                  <GoogleInstructionsPage></GoogleInstructionsPage>
                )}
              />
              <Route
                path="/select-phone"
                exact
                render={(props) => <SelectPhoneTypePage></SelectPhoneTypePage>}
              />
              <Route
                path="/android-instructions"
                exact
                render={(props) => (
                  <AndroidInstructionsPage></AndroidInstructionsPage>
                )}
              />
              <Route
                path="/iphone-instructions"
                exact
                render={(props) => (
                  <IphoneInstructionsPage></IphoneInstructionsPage>
                )}
              />
              <Route
                path="/in-app"
                exact
                render={(props) => (
                  <ListenInAppInstructions></ListenInAppInstructions>
                )}
              />
              <Route
                path="/account"
                exact
                render={(props) => <AccountHomePage></AccountHomePage>}
              />
              <Route
                path="/collect-payment"
                exact
                render={(props) => <CollectPaymentPage></CollectPaymentPage>}
              />
              <Route
                path="/spread-the-word"
                exact
                render={(props) => <SpreadTheWordPage></SpreadTheWordPage>}
              />
              <Route
                path="/get-started"
                exact
                render={(props) => <CreatorTutorialPage></CreatorTutorialPage>}
              />
              <Route
                path="/manage-account"
                exact
                render={(props) => <MyAccountPage></MyAccountPage>}
              />
              <Route
                path="/nominate"
                exact
                render={(props) => <NominatePage></NominatePage>}
              />
              <Route
                path="/start"
                exact
                render={(props) => <IntroSurveyPage></IntroSurveyPage>}
              />
              <Route
                path="/pre-info"
                exact
                render={(props) => <CreatorPreinfoPage></CreatorPreinfoPage>}
              />
              <Route
                path="/prereg-required"
                exact
                render={(props) => (
                  <PreregistrationRequired></PreregistrationRequired>
                )}
              />
              <Route
                path="/"
                exact
                render={(props) => <LandingPage></LandingPage>}
              />
              {/* <Route path="/" exact render={(props) => <HomePage></HomePage>} /> */}
              {/* <Route
              path="/home1"
              exact
              render={(props) => (
                <ProtectedContent title="1"></ProtectedContent>
              )}
            />
            <Route
              path="/home2"
              exact
              render={(props) => (
                <ProtectedContent title="2"></ProtectedContent>
              )}
            /> */}
              <Redirect to="/" />
            </Switch>
            {/* <Login></Login> */}
            {/* <ProtectedContent></ProtectedContent> */}
            {/* <ResetPassword></ResetPassword> */}
            {/* <AccountConfirmed></AccountConfirmed> */}
            {/* <ForgotPassword></ForgotPassword> */}
          </header>
        </div>
      </Provider>
    </Elements>
  );
}

export default withRouter(App);
