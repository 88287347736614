import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SuccessMessage from "../../components/SuccessMessage/SuccessMessage";
import * as authActions from "../../store/actions/auth";
import { writeLog } from "../../shared/logger";

import classes from "./ConfirmationInstructions.module.scss";
// import dev_config from "../../config_dev";
// import prod_config from "../../config_prod";
// const config = dev_config.useProd ? prod_config : dev_config;

export default function ConfirmationInstructions() {
  //console.log("showing confirmation instructions");
  const history = useHistory();

  const dispatch = useDispatch();
  const isAuthed = useSelector((state) => !!state.authStore.authorized);

  useEffect(() => {
    const asyncWork = async () => {
      if (isAuthed) {
        writeLog("logging out logged in unverified user");
        try {
          const response = await dispatch(authActions.logout());
          writeLog("have signout response: ", response);
        } catch (e) {
          //console.log(e.message);
        }
      }
    };
    asyncWork();
  }, [isAuthed, dispatch]);

  const instructions = (
    <React.Fragment>
      <SuccessMessage
        style={{ marginLeft: "20%", marginRight: "20%" }}
        title="Almost there!"
        //titleStyle={{ color: "" }}
        subtitle="We need to verify your email address in our system - please check your email for our verification message."
        hideIcon
      ></SuccessMessage>
      <div className={classes.ButtonContainer}>
        <Button
          size="lg"
          className={classes.BackToLoginButton}
          onClick={() => {
            //console.log("clicked back to login");
            history.push("/auth");
          }}
        >
          Back to Login
        </Button>
      </div>
    </React.Fragment>
  );

  return instructions;
}
