import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faPlus,
  faMinus,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import SubmitButton from "../SubmitButton/SubmitButton";
import { loadStripe } from "@stripe/stripe-js";
import { apiPostUnauthed } from "../../shared/apiWrapper";

import classes from "./Give.module.scss";

import dev_config from "../../config_dev";
import prod_config from "../../config_prod";
const config = dev_config.useProd ? prod_config : dev_config;

const stripePromise = loadStripe(config.stripePk);

const stripePricesTest = {
  5: "price_1HXTEeK34k1knMFTHJqoHu7y",
  10: "price_1HXTEeK34k1knMFTfJfNJ8BT",
  15: "price_1HXTEeK34k1knMFTCwlkAmE4",
  20: "price_1HXTEeK34k1knMFTpwsmdSnQ",
  25: "price_1HXTEeK34k1knMFTgBGTCftE",
  30: "price_1HXTEfK34k1knMFTHLOYDkOo",
  35: "price_1HXTEfK34k1knMFTn1EZR0YT",
  40: "price_1HXTEeK34k1knMFTqteFsjeS",
  45: "price_1HXTEeK34k1knMFTFd6duFLO",
  50: "price_1HXTEgK34k1knMFTrqlgZ1xk",
  55: "price_1HXTEeK34k1knMFTqI7SYYmd",
  60: "price_1HXTEfK34k1knMFTHacI2HPM",
  65: "price_1HXTEgK34k1knMFT22hnPUBB",
  70: "price_1HXTEfK34k1knMFTNoyjoCkp",
  75: "price_1HXTEfK34k1knMFTmWJpE6hE",
  80: "price_1HXTEfK34k1knMFTcHXKpECE",
  85: "price_1HXTEfK34k1knMFToY5OqObG",
  90: "price_1HXTEfK34k1knMFTYG07eYqH",
  95: "price_1HXTEfK34k1knMFTChDA70Z1",
  100: "price_1HXTEgK34k1knMFTOLrVEocA",
};

const stripePricesLive = {
  5: "price_1HXTbBK34k1knMFTylx0RhlZ",
  10: "price_1HXTbBK34k1knMFTRnJDc32M",
  15: "price_1HXTbBK34k1knMFT2FjLU9pt",
  20: "price_1HXTbBK34k1knMFTQi3Wu3nJ",
  25: "price_1HXTbBK34k1knMFTLKRrmAgn",
  30: "price_1HXTbBK34k1knMFT3olmlJD3",
  35: "price_1HXTbBK34k1knMFT0iOjCoPO",
  40: "price_1HXTbBK34k1knMFTLXJbmyZL",
  45: "price_1HXTbBK34k1knMFTMIQQkOwz",
  50: "price_1HXTbBK34k1knMFTvbV6pBMc",
  55: "price_1HXTbBK34k1knMFTXg7qch89",
  60: "price_1HXTbBK34k1knMFT5rwZujWM",
  65: "price_1HXTbBK34k1knMFTPAA4N5Z6",
  70: "price_1HXTbBK34k1knMFTnFDzpkeR",
  75: "price_1HXTbBK34k1knMFTprNVy20q",
  80: "price_1HXTbBK34k1knMFT8Aurc4N5",
  85: "price_1HXTbBK34k1knMFTmgMi8YLm",
  90: "price_1HXTbBK34k1knMFTOcsZMLqf",
  95: "price_1HXTbBK34k1knMFT7RCRd2LO",
  100: "price_1HXTbCK34k1knMFTMM2hHjja",
};

const stripePrices = config.useLiveStripe ? stripePricesLive : stripePricesTest;

const Give = (props) => {
  const [sliderValue, setSliderValue] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSuccessfullySubmitted, setHasSucessfullySubmitted] =
    useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      //console.log("handle submit called");
      // Get Stripe.js instance
      const stripe = await stripePromise;

      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          {
            price: stripePrices[sliderValue], // Replace with the ID of your price
            quantity: 1,
          },
        ],
        mode: "payment",
        successUrl: "https://holymin.com/#/thank-you",
        cancelUrl: "https://holymin.com/#/give",
        submitType: "donate",
      });
      setIsLoading(false);

      if (error) {
        //console.log(error.message);
      }
    } catch (err) {
      console.log(
        "caught error trying to create giving checkout session: ",
        err
      );
      setIsLoading(false);
    }
  };

  const onSubmissionFinish = () => {
    //console.log("on submission finish called");
  };

  const submitButton = (
    <SubmitButton
      disabled={sliderValue < 5}
      type="submit"
      isLoading={isLoading}
      hasSuccessfullySubmitted={hasSuccessfullySubmitted}
      onClick={handleSubmit}
      onSubmissionFinish={onSubmissionFinish}
    >
      GIVE
    </SubmitButton>
  );

  return (
    <div className={classes.FullScreenMessage} style={{ ...props.style }}>
      <div
        className={classes.Title}
        style={{ ...props.titleStyle, maxWidth: "80%" }}
        key={20}
      >
        Support Holy Minute
      </div>
      <div className={classes.Subtitle} key={21}>
        Our work is made possible through the generous contributions of our
        users. Thank you for your generosity!
      </div>
      <div></div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          marginBottom: "20px",
          marginTop: "30px",
        }}
      >
        <div
          className={
            sliderValue > 5
              ? `${classes.IconContainer}`
              : `${classes.IconContainerDisabled}`
          }
          onClick={() => {
            if (sliderValue > 5) {
              setSliderValue((prev) => prev - 5);
            }
          }}
        >
          <FontAwesomeIcon
            icon={faMinus}
            style={{
              fontSize: 20,
              margin: 5,
              color: sliderValue > 5 ? "#6d219e" : "grey",
              opacity: sliderValue > 5 ? 1 : 0.5,
            }}
            className={`${classes.SocialLink}`}
          ></FontAwesomeIcon>
        </div>
        <div
          className={`${classes.DonationAmountContainer}`}
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: 20,
          }}
        >
          <div
            className={`${classes.DonationValue}`}
            style={{
              display: "flex",
              fontSize: "20px",
              alignSelf: "flex-start",
              marginTop: 3,
              marginBottom: 0,
              fontWeight: "normal",
            }}
          >
            $
          </div>
          <span className={`${classes.DonationValue}`}>{sliderValue}</span>
        </div>
        <div
          className={
            sliderValue < 100
              ? `${classes.IconContainer}`
              : `${classes.IconContainerDisabled}`
          }
          onClick={() => {
            if (sliderValue < 100) {
              setSliderValue((prev) => prev + 5);
            }
          }}
        >
          <FontAwesomeIcon
            icon={faPlus}
            style={{
              fontSize: 20,
              margin: 5,
              color: sliderValue < 100 ? "#6d219e" : "light-grey",
              opacity: sliderValue < 100 ? 1 : 0.5,
            }}
            className={`${classes.SocialLink}`}
          ></FontAwesomeIcon>
        </div>
      </div>
      {submitButton}

      <div className={`${classes.PoliciesText}`}>
        All contributions are subject to our{" "}
        <a className={`${classes.PolicyLink}`} href="www.holymin.com/#/terms">
          Terms of Use
        </a>{" "}
        and{" "}
        <a
          className={`${classes.PolicyLink}`}
          href="www.holymin.com/#/privacy-policy"
        >
          Privacy Policy
        </a>
      </div>
      {/* {props.hideIcon ? null : (
        <div>
          <div className={classes.IconContainer}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "3em" }}
            ></FontAwesomeIcon>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Give;
