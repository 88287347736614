import React from "react";

import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";
import Give from "../../components/Give/Give";

import classes from "./GivePage.module.scss";

const GivePage = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        width: "100%",
        backgroundColor: "whitesmoke",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={classes.LogoImage}>
        <Image src={logo} fluid />
      </div>
      {/* <h1>Home Page</h1> */}
      {/* <ProtectedContent></ProtectedContent> */}
      <Give></Give>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
    </div>
  );
};

export default GivePage;
