import React, { useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";

// import { updateObject, checkValidity } from "../../shared/utility";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import classes from "./SubmitButton.module.scss";

// import dev_config from "../../config_dev";
// import prod_config from "../../config_prod";
// const config = dev_config.useProd ? prod_config : dev_config;

const SubmitButton = (props) => {
  const { hasSuccessfullySubmitted, onSubmissionFinish } = props;

  useEffect(() => {
    if (hasSuccessfullySubmitted) {
      setTimeout(() => {
        onSubmissionFinish();
      }, 650);
    }
  }, [hasSuccessfullySubmitted, onSubmissionFinish]);

  let submitButtonContent = props.children;
  if (props.isLoading) {
    submitButtonContent = (
      <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
    );
  }
  if (props.hasSuccessfullySubmitted) {
    submitButtonContent = (
      <FontAwesomeIcon
        icon={faCheck}
        style={{ textAlign: "center" }}
      ></FontAwesomeIcon>
    );
  }

  return (
    <div className={classes.ButtonContainer}>
      <Button
        size="lg"
        disabled={props.disabled}
        type={props.type}
        onClick={props.onClick}
        className={`${classes.SubmitButton} ${
          props.hasSuccessfullySubmitted ? classes.SubmitButtonSuccess : ""
        }`}
        style={props.buttonStyle ? props.buttonStyle : {}}
        //style={{ backgroundColor: "navy" }}
      >
        {submitButtonContent}
      </Button>
    </div>
  );
};

export default SubmitButton;
