import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import TextInput from "../../components/TextInput/TextInput";
import SuccessMessage from "../../components/SuccessMessage/SuccessMessage";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import { writeLog } from "../../shared/logger";

import { useDispatch } from "react-redux";
import * as authActions from "../../store/actions/auth";

import classes from "./ForgotPassword.module.scss";
import { updateObject, checkValidity } from "../../shared/utility";
import { FORGOT_PASSOWORD_CONTROLS } from "./ForgotPasswordFormConfig";
//import config from "../../config";

export default function ForgotPassword() {
  const dispatch = useDispatch();

  const history = useHistory();

  const [forgotForm, setForgotForm] = useState(FORGOT_PASSOWORD_CONTROLS);

  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [hasSuccessfullySubmitted, setHasSuccessfullySubmitted] =
    useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputChangedHandler = (event, controlName) => {
    if (formErrorMessage) {
      setFormErrorMessage(false);
    }

    const { validity, errorMessage } = checkValidity(
      event.target.value,
      forgotForm[controlName].validation
    );

    const updatedControls = updateObject(forgotForm, {
      [controlName]: updateObject(forgotForm[controlName], {
        value: event.target.value,
        valid: validity,
        invalidMessage: errorMessage,
      }),
    });
    setForgotForm(updatedControls);
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setSubmitAttempted(true);

    if (!allValid) return;

    setIsLoading(true);

    try {
      await dispatch(
        authActions.requestForgotPassword(forgotForm["email"].value)
      );

      //await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
      setHasSuccessfullySubmitted(true);
      //alert("Changed password");
    } catch (e) {
      //console.log(e);
      setFormErrorMessage(e.message);
      setIsLoading(false);
      //alert(e.message);
    }
  }

  const onSubmissionFinish = () => {
    setShowSuccessMessage(true);
  };

  let allValid = true;
  let allFilled = true;
  const formElementsArray = [];
  for (let key in forgotForm) {
    formElementsArray.push({
      id: key,
      config: forgotForm[key],
    });
    allFilled = allFilled && forgotForm[key].value;
    allValid = allValid && forgotForm[key].valid;
  }

  let inputs = formElementsArray.map((formElement) => (
    <div className={classes.InputContainer} key={formElement.id}>
      <TextInput
        id={formElement.id}
        type={formElement.config.elementConfig.type}
        label={formElement.config.elementConfig.placeholder}
        locked={isLoading || hasSuccessfullySubmitted}
        active={false}
        setValue={(event) => inputChangedHandler(event, formElement.id)}
        shouldValidate={formElement.config.validation}
        error={
          formElement.config.validation &&
          !formElement.config.valid &&
          submitAttempted &&
          formElement.config.invalidMessage
        }
        value={formElement.config.value}
        clearFormSubmissionError={() => {
          setFormErrorMessage("");
        }}
        autoComplete="off"
      />
    </div>
  ));

  const passwordResetSuccessMessage = (
    <React.Fragment>
      <SuccessMessage
        title="Help is on the way!"
        subtitle="Check your email for a link to reset your password"
        hideIcon
      ></SuccessMessage>
      <div className={classes.ButtonContainer}>
        <Button
          size="lg"
          className={classes.BackToLoginButton}
          onClick={() => {
            writeLog("clicked back to login");
            history.push("/");
          }}
        >
          Back to Login
        </Button>
      </div>
    </React.Fragment>
  );

  const submitButton = (
    <SubmitButton
      disabled={!allFilled}
      type="submit"
      onClick={handleSubmit}
      onSubmissionFinish={onSubmissionFinish}
      hasSuccessfullySubmitted={hasSuccessfullySubmitted}
      isLoading={isLoading}
    >
      SUBMIT
    </SubmitButton>
  );

  const form = (
    <React.Fragment>
      <form
        onSubmit={handleSubmit}
        className={classes.ForgotForm}
        autoComplete="off"
      >
        <div className={classes.TitleTopLine} key={20}>
          Request Password
        </div>
        <div className={classes.Title} key={21}>
          Reset
        </div>
        <div>
          {inputs}
          {submitButton}
          {formErrorMessage && (
            <div className={classes.FailedResetMessage}>{formErrorMessage}</div>
          )}
        </div>
      </form>
    </React.Fragment>
  );

  return showSuccessMessage ? passwordResetSuccessMessage : form;
}
