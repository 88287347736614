export default {
  apiGateway: {
    REGION: "us-east-1",
    URL: " https://n994s5qab8.execute-api.us-east-1.amazonaws.com/dev",
    API_NAME: "holyminute-dev",
    API_KEY: "7HDBp9VFvb9xCJbxuDeC566qjXUYZW8Ra6O5RBoQ",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Aj3m42obc",
    APP_CLIENT_ID: "7t11d4tuj1jmovsp0o0kiphf5r",
    IDENTITY_POOL_ID: "us-east-1:d0b84804-d614-4182-bb6f-8b00e7060a22",
  },
  loginTitle: "Holy Minute-dev",
  platformDescription:
    "a Religious platform designed to connect the Church and the faithful in prayer.",
  useProd: true,
  useLogs: false,
  stripePk:
    "pk_test_51GxAMTK34k1knMFTjPHEDiaKMs54IHcKKALeAD2jAJZlS2NExu9IUTLbtFLUqWr9vLu2ArkWQT4Kfr7CsHywi9ti00Ik1vUliu",
  useLiveStripe: false,
};
