import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TextInput from "../../components/TextInput/TextInput";
import SuccessMessage from "../../components/SuccessMessage/SuccessMessage";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import { writeLog } from "../../shared/logger";

import { useDispatch } from "react-redux";
import * as authActions from "../../store/actions/auth";

import classes from "./ResetPassword.module.scss";
import { updateObject, checkValidity } from "../../shared/utility";
import { RESET_PASSOWORD_CONTROLS } from "./ResetPasswordFormConfig";

const EXPIRED_CODE_EXCEPTION = "ExpiredCodeException";

export default function ResetPassword() {
  const dispatch = useDispatch();

  const { code, userName } = useParams();

  writeLog(
    `have params in reset password - code: ${code}, userName: ${userName}`
  );

  const [resetForm, setResetForm] = useState(RESET_PASSOWORD_CONTROLS);

  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [hasSuccessfullySubmitted, setHasSuccessfullySubmitted] = useState(
    false
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputChangedHandler = (event, controlName) => {
    if (formErrorMessage) {
      setFormErrorMessage(false);
    }

    const { validity, errorMessage } = checkValidity(
      event.target.value,
      resetForm[controlName].validation
    );

    const updatedControls = updateObject(resetForm, {
      [controlName]: updateObject(resetForm[controlName], {
        value: event.target.value,
        valid: validity,
        invalidMessage: errorMessage,
      }),
    });
    setResetForm(updatedControls);
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setSubmitAttempted(true);

    if (!allValid) return;

    if (
      resetForm["newPassword"].value !== resetForm["confirmNewPassword"].value
    ) {
      setFormErrorMessage("Passwords must match");
      return;
    }

    setIsLoading(true);

    try {
      writeLog(userName);
      writeLog(code);
      writeLog(resetForm["newPassword"].value);
      await dispatch(
        authActions.submitForgotPassword(
          userName,
          code,
          resetForm["newPassword"].value
        )
      );
      //await new Promise((resolve) => setTimeout(resolve, 2000));
      //throw new Error("there was an error");
      setIsLoading(false);
      setHasSuccessfullySubmitted(true);

      //alert("Changed password");
    } catch (e) {
      if (e.code === EXPIRED_CODE_EXCEPTION) {
        setFormErrorMessage(
          "Reset password link has expired. Please request a new link"
        );
      } else {
        setFormErrorMessage(e.message);
      }

      setIsLoading(false);
      //alert(e.message);
    }
  }

  const onSubmissionFinish = () => {
    setShowSuccessMessage(true);
  };

  let allValid = true;
  let allFilled = true;
  const formElementsArray = [];
  for (let key in resetForm) {
    formElementsArray.push({
      id: key,
      config: resetForm[key],
    });
    allFilled = allFilled && resetForm[key].value;
    allValid = allValid && resetForm[key].valid;
  }

  let inputs = formElementsArray.map((formElement) => (
    <div className={classes.InputContainer} key={formElement.id}>
      <TextInput
        id={formElement.id}
        type={formElement.config.elementConfig.type}
        label={formElement.config.elementConfig.placeholder}
        locked={isLoading || hasSuccessfullySubmitted}
        active={false}
        setValue={(event) => inputChangedHandler(event, formElement.id)}
        shouldValidate={formElement.config.validation}
        error={
          formElement.config.validation &&
          !formElement.config.valid &&
          submitAttempted &&
          formElement.config.invalidMessage
        }
        value={formElement.config.value}
        clearFormSubmissionError={() => {
          setFormErrorMessage("");
        }}
        autoComplete="off"
      />
    </div>
  ));

  const passwordResetSuccessMessage = (
    <SuccessMessage
      title="Welcome Back!"
      subtitle="You have successfully reset your password"
    ></SuccessMessage>
  );

  const submitButton = (
    <SubmitButton
      disabled={!allFilled}
      type="submit"
      isLoading={isLoading}
      hasSuccessfullySubmitted={hasSuccessfullySubmitted}
      onClick={handleSubmit}
      onSubmissionFinish={onSubmissionFinish}
    >
      SUBMIT
    </SubmitButton>
  );

  const form = (
    <React.Fragment>
      <form
        onSubmit={handleSubmit}
        className={classes.ResetForm}
        autoComplete="off"
      >
        <div className={classes.Title} key={20}>
          Reset Password
        </div>
        {/* <div> */}
        {inputs}
        {submitButton}

        {formErrorMessage && (
          <div className={classes.FailedResetMessage}>{formErrorMessage}</div>
        )}
        {/* </div> */}
      </form>
    </React.Fragment>
  );

  return showSuccessMessage ? passwordResetSuccessMessage : form;
}
