import React from "react";

import SimpleMarketing from "../../components/SimpleMarketing/SimpleMarketing";

//import classes from "./SimpleMarketingPage.module.scss";

const SimpleMarketingPage = (props) => {
  return (
    <div>
      {/* <h1>Home Page</h1> */}
      {/* <ProtectedContent></ProtectedContent> */}
      <SimpleMarketing></SimpleMarketing>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
    </div>
  );
};

export default SimpleMarketingPage;
