import React from "react";
import { useParams } from "react-router-dom";

import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";
import Listen from "../../components/Listen/Listen";
//import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import classes from "./ListenPage.module.scss";

const ListenPage = (props) => {
  const { priestName } = useParams();

  const formattedPriestName = priestName.split("_").join(" ");

  //console.log("search name: ", formattedPriestName);

  return (
    //<GoogleReCaptchaProvider reCaptchaKey="6Lf8XdQZAAAAANbtKXWDo02mehhJ0e1-cK9ktNfg">
    <div
      style={{
        display: "flex",
        flex: 1,
        width: "100%",
        backgroundColor: "whitesmoke",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div className={classes.LogoImage}>
        <Image src={logo} fluid />
      </div>
      {/* <h1>Home Page</h1> */}
      {/* <ProtectedContent></ProtectedContent> */}
      <Listen formattedPriestName={formattedPriestName}></Listen>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
    </div>
    //</GoogleReCaptchaProvider>
  );
};

export default ListenPage;
