import { Auth } from "aws-amplify";
import { writeLog } from "../../shared/logger";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SIGN_UP = "SIGN_UP";
export const REFRESH_SESSION = "REFRESH_SESSION";

export const login = (username, password) => {
  return async (dispatch) => {
    try {
      const response = await Auth.signIn(username, password);
      writeLog(
        "successful amplify sign in, response: ",
        response.attributes.email_verified
      );
      dispatch({ type: LOGIN, isVerified: response.attributes.email_verified });
    } catch (e) {
      //console.log("amplify signin error");
      throw e;
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      await Auth.signOut();
      writeLog("successful amplify sign out");
      dispatch(setSignedOut());
    } catch (e) {
      //console.log("amplify signout error");
      throw e;
    }
  };
};

export const setSignedOut = () => {
  return { type: LOGOUT };
};

export const setSignedIn = () => {
  return { type: LOGIN };
};

export const signUp = (username, password) => {
  return async (dispatch) => {
    try {
      await Auth.signUp(username, password);
      writeLog("successful amplify sign up");
      dispatch({ type: SIGN_UP });
    } catch (e) {
      //console.log("amplify signin error");
      throw e;
    }
  };
};

export const confirmAccount = (username, code) => {
  return async (dispatch) => {
    try {
      await Auth.confirmSignUp(username, code);
      writeLog("successful amplify account confirmation");
    } catch (e) {
      //console.log("amplify account confirmation error");
      throw e;
    }
  };
};

export const resendConfirmation = (username) => {
  return async (dispatch) => {
    try {
      await Auth.resendSignUp(username);
      writeLog("successful amplify account confirmation");
    } catch (e) {
      //console.log("amplify account confirmation error");
      throw e;
    }
  };
};

export const requestForgotPassword = (username) => {
  return async (dispatch) => {
    try {
      await Auth.forgotPassword(username);
      writeLog("successful amplify forgot password request");
    } catch (e) {
      //console.log("amplify forgot password request error");
      throw e;
    }
  };
};

export const submitForgotPassword = (username, code, password) => {
  return async (dispatch) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      writeLog("successful amplify forgot password submit");
    } catch (e) {
      //console.log("amplify forgot password submit error");
      throw e;
    }
  };
};

export const refreshSession = (username, password) => {
  return async (dispatch) => {
    try {
      await Auth.currentSession();
      writeLog("successfully refreshed session");
      dispatch({ type: REFRESH_SESSION });
    } catch (e) {
      writeLog("tried to refresh session but none existed");
      dispatch(setSignedOut());
    }
  };
};
