import React from "react";

import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import classes from "./ThanksForGivingPage.module.scss";

const ThanksForGivingPage = (props) => {
  let history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        width: "100%",
        backgroundColor: "whitesmoke",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={classes.FullScreenMessage} style={{ ...props.style }}>
        <div className={classes.Title} style={{ ...props.titleStyle }} key={20}>
          Thank you!
        </div>
        <div className={classes.Subtitle} key={21}>
          Holy Minute relies on the generosity of users like you. Thank you very
          much!
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "whitesmoke",
          }}
        >
          <div
            onClick={() => {
              //console.log("clicked get started");
              //setRedirectUrl("/auth");
              history.push("/");
            }}
            className={`${classes.GetStartedButton}`}
            style={{}}
          >
            <div>Home </div>
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{
                fontSize: "20px",
                margin: "5px",
                marginLeft: "15px",
                paddingTop: "4px",
              }}
            ></FontAwesomeIcon>
          </div>
        </div>
        {/* {props.hideIcon ? null : (
        <div>
          <div className={classes.IconContainer}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "3em" }}
            ></FontAwesomeIcon>
          </div>
        </div>
      )} */}
      </div>
    </div>
  );
};

export default ThanksForGivingPage;
