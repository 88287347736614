import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import classes from "./ErrorMessage.module.scss";

const ErrorMessage = (props) => {
  return (
    <div className={classes.FullScreenMessage}>
      <div className={`${classes.Title} ${classes.ErrorText}`} key={20}>
        {props.title}
      </div>
      <div className={`${classes.Subtitle} `} key={21}>
        {props.subtitle}
      </div>
      <div>
        {props.hideIcon ? null : (
          <div className={`${classes.IconContainer} ${classes.ErrorIcon}`}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ fontSize: 40 }}
            ></FontAwesomeIcon>
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorMessage;
