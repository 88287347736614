const forgotPasswordControls = {
  email: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Email",
    },
    value: "",
    validation: {
      required: true,
      isEmail: true,
    },
    valid: false,
    invalidMessage: "",
  },
};

module.exports.FORGOT_PASSOWORD_CONTROLS = forgotPasswordControls;
