import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./NominatePage.module.scss";

const NominatePage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Holy Minute
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            Nominations
            <div className={classes.SubQuestionText}>
              Help us keep the Holy Minute content quality high{" "}
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "block",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                }}
              >
                <div className={`${classes.InstructionText}`}>
                  <div>
                    At Holy Minute, we want to make sure that only the highest
                    quality content makes it onto our platform. As such, we will
                    be employing all appropriate means to control who can post.
                    <br></br>
                    <br></br>If you know of someone who wants to post prayers on
                    Holy Minute, please send us an email at
                    holymin@harbourviewtechnologies.com including thier name,
                    email address, and how you know them! That way, we will know
                    we can trust them. Thank you!
                  </div>
                </div>
                {/* <div className={`${classes.InstructionText}`}>
                  <div>
                    1. Enable the skill on your Alexa device by saying,{" "}
                    <span className={classes.SpokenWords}>
                      "Alexa, enable Holy Minute."
                    </span>
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    2. To hear someone's Holy Minute post for the day, say{"  "}
                    <span className={classes.SpokenWords}>
                      "Alexa, ask Holy Minute to pray with{"  "}
                      <span style={{ fontStyle: "italic" }}>~name~</span>."
                    </span>
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    3. To pray a traditional prayer, say{" "}
                    <span className={classes.SpokenWords}>
                      "Alexa, ask Holy Minute to pray{"  "}
                      <span style={{ fontStyle: "italic" }}>~prayer name~</span>
                      {"  "}
                      with <span style={{ fontStyle: "italic" }}>~name~</span>."
                    </span>
                    <br></br>
                    Supported traditional prayers include the Our Father, Hail
                    Mary, Glory Be, Act of Contrition, Apostles Creed, Nicene
                    Creed, and a mealtime prayer.
                    <br></br>
                    <br></br>
                    For more detailed instructions, please see our{" "}
                    <a
                      className={classes.Link}
                      href="https://www.amazon.com/Harbourview-Technologies-LLC-Holy-Minute/dp/B08B3YT73G"
                    >
                      Alexa Skill store page
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          // </div>
        )}
      </Transition>
    </div>
  );
};

export default withAuth(NominatePage);
