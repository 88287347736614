import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./SpreadTheWordPage.module.scss";

const SpreadTheWordPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Holy Minute
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            Spread the Word
            <div className={classes.SubQuestionText}>
              How to let your listeners know you are on Holy Minute and help
              them find you there{" "}
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "block",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                }}
              >
                <div className={`${classes.InstructionText}`}>
                  <div>
                    When you want to tell people about praying with you on Holy
                    Minute, your communications should include the following:
                    <br></br>
                    <br></br>
                    1.{" "}
                    <span className={classes.SpokenWords}>
                      Your Holy Minute name
                    </span>{" "}
                    - your listeners will need this to be able to find you on
                    the app or to ask for you on Alexa and Google assistant
                    <br></br>
                    <br></br>
                    2.{" "}
                    <span className={classes.SpokenWords}>
                      The Holy Minute url
                    </span>{" "}
                    - you should send people to{" "}
                    <a className={classes.Link} href="https://www.holymin.com">
                      www.holymin.com
                    </a>{" "}
                    for instructions on how to listen on whatever device they
                    may have
                    <br></br>
                    <br></br>
                    3.{" "}
                    <span className={classes.SpokenWords}>
                      The range of devices
                    </span>{" "}
                    - you will want to make sure people know that they can
                    access Holy Minute through the smartphone app as well as
                    from any Alexa or Google Assistant-enabled device. We want
                    them to know that everyone can find a way to listen, no
                    matter what device they own!
                    <br></br>
                    <br></br>
                    <span style={{ fontStyle: "italic" }}>Nice to have:</span>
                    <br></br>
                    <span className={classes.SpokenWords}>
                      The Holy Minute Logo
                    </span>{" "}
                    - this will help users know they have come to the right
                    place anytime they see this logo
                    <br></br>
                    <span className={classes.SpokenWords}>
                      Posting frequency
                    </span>{" "}
                    - it's a good idea to let people know how often you expect
                    to be posting new content so they know what to expect
                    <br></br>
                    <br></br>
                    <div style={{ textAlign: "center" }}>
                      <span
                        className={classes.SpokenWords}
                        style={{ fontSize: 28 }}
                      >
                        Example:
                      </span>
                    </div>{" "}
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    <span className={classes.SpokenWords}>
                      Pray with
                      <span
                        style={{
                          fontStyle: "italic",
                          marginLeft: 4,
                          marginRight: 4,
                        }}
                      >
                        ~name~
                      </span>
                      on Holy Minute!
                    </span>{" "}
                    We are excited to announce that{"   "}
                    <span
                      style={{
                        fontStyle: "italic",
                        marginLeft: 4,
                        marginRight: 4,
                      }}
                    >
                      ~name~
                    </span>
                    {"   "} is now on Holy Minute. Holy Minute allows you to
                    spend a moment in prayer and reflection with me using your
                    smartphone or your Alexa or Google-assistant enabled
                    devices. All you have to do is ask to pray with {"  "}
                    <span
                      className={classes.SpokenWords}
                      style={{
                        fontStyle: "italic",
                        marginLeft: 4,
                        marginRight: 4,
                      }}
                    >
                      ~Your Holy Minute name~
                    </span>
                    {"  "} to listen to my latest Holy Minute! To learn more
                    about how to listen, find Holy Minute on the App Store or
                    Google Play or visit www.holymin.com.
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </div>
        )}
      </Transition>
    </div>
  );
};

export default withAuth(SpreadTheWordPage);
