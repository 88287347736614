import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import LoginButton from "../../components/LoginButton/LoginButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebookSquare,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./IntroSurveyPage.module.scss";

const IntroSurveyPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  return (
    <div className={`${classes.FullScreen}`}>
      <LoginButton></LoginButton>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            Holy Minute
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <React.Fragment>
            <div
              style={{
                ...questionsDefaultStyle,
                ...questionsTransitionStyles[state],
              }}
              className={classes.QuestionText}
            >
              I would like to...
              <div className="row" style={{ flex: "1 1 auto" }}>
                <div
                  className={`col-sm-12 col-lg-6 col-md-6 ${classes.LeftRowItem}`}
                >
                  <div
                    onClick={() => {
                      //console.log("clicked listen");
                      history.push("/select-device");
                    }}
                    className={`${classes.AnswerText}`}
                  >
                    <div>Listen</div>
                    <div className={classes.AnswerSubtext}>
                      Learn how to spend a Holy Minute with us
                    </div>
                    <div className={classes.AnswerSubicon}>
                      {/* Learn more about posting my prayers on Holy Minute */}
                      <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        style={{ fontSize: "25", margin: "5px" }}
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                </div>
                <div
                  className={`col-sm-12 col-lg-6 col-md-6 ${classes.RightRowItem}`}
                >
                  <div
                    onClick={() => {
                      //console.log("clicked contribute");
                      //setRedirectUrl("/auth");
                      history.push("/pre-info");
                    }}
                    className={`${classes.AnswerText}`}
                  >
                    <div>Create</div>
                    <div className={classes.AnswerSubtext}>
                      Learn more about posting my prayers and reflections on
                      Holy Minute
                    </div>
                    <div className={classes.AnswerSubicon}>
                      {/* Learn more about posting my prayers on Holy Minute */}
                      <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        style={{ fontSize: "25", margin: "5px" }}
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </Transition>
      <div
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          // border: "solid",
          // borderWidth: 1,
          //paddingTop: "20%",
          paddingBottom: "20px",
          backgroundColor: "whitesmoke",
        }}
        className={`SocialLinkResponsive`}
      >
        <div
          style={{
            fontSize: 10,
            width: 220,
            fontWeight: "bold",
            //borderWidth: 1,
            //border: "solid",
            //borderColor: "red",
            //borderRadius: 15,
            //backgroundColor: "#9A66BC",
            //color: "white",
            color: "#444444",
          }}
        >
          Discover more priests and religous to pray with on Holy Minute:
          <div className={`${classes.IconContainer} ${classes.ErrorIcon}`}>
            <a href="https://www.instagram.com/holyminute/">
              <FontAwesomeIcon
                icon={faInstagram}
                style={{ fontSize: 35, margin: 5, color: "#6d219e" }}
                className={`${classes.SocialLink}`}
              ></FontAwesomeIcon>
            </a>
            <a href="https://www.facebook.com/Holy-Minute-109285774177742">
              <FontAwesomeIcon
                icon={faFacebookSquare}
                style={{ fontSize: 35, margin: 5, color: "#6d219e" }}
                className={`${classes.SocialLink}`}
              ></FontAwesomeIcon>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroSurveyPage;
